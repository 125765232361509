import { NavLink } from 'react-router-dom';
import { MdLockOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';

const NavTabs = ({ tabsData }) => {
  const SubscriptionData = useSelector(state => state.Subscription);

  const PlanFeatureAvailable = (feature = '') => {
    let data = SubscriptionData;
    // if (user?.on_trial) {
    //   return true
    // } else {
    // return true
    let value = false;
    if (feature?.length) {
      feature?.map((_itm) => {
        if (data?.includes(_itm)) {
          value = true
        }
      })
    }
    else {
      value = data?.includes(feature)
    }
    return value;
    // }


  }
  return (
    <ul className="flex gap-[20px] items-start border-b border-[#EAECF0] mb-6 w-full flex-wrap gap-y-4">
      {tabsData?.map((tabItem, index) => (
        <li key={index} className={`${tabItem?.keySubscribe ? PlanFeatureAvailable(tabItem?.keySubscribe) ? '' : 'pointer-events-none' : ""} `} >
          <NavLink
            to={tabItem.link}
            style={{ display: 'flex', alignItems: 'center' }}
            className={({ isActive }) => `${tabItem?.keySubscribe ? PlanFeatureAvailable(tabItem?.keySubscribe) ? '' : 'pointer-events-none' : ""} pt-[1px] px-[4px] pb-[11px] block text-sm font-normal hover:!text-primary   hover:no-underline
             ${isActive ? 'border-b-2 !border-primary !text-primary   !font-medium' : '!text-[#667085]'} `}>
            {tabItem.title} {tabItem?.keySubscribe ? PlanFeatureAvailable(tabItem?.keySubscribe) ? '' : <MdLockOutline /> : ""}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
export default NavTabs;