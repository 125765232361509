let apiUrl = 'https://app2api.dazhboards.com/'
let url = `https://app2.dazhboards.com/`
let ImageUrl = 'https://dev-dazhboards-assets-bucket.s3.amazonaws.com/'
let ReviewApi = 'https://reviews-dev.dazhboards.com/api/'
let chatbot_api = 'https://bot-dev.dazhboards.com/'
// const CurrentServer = true;
const CurrentServer = window.location.href?.includes("app.dazhboards.com");
if (CurrentServer) {
  ImageUrl = 'https://prod-dazhboards-assets-bucket.s3.ap-southeast-2.amazonaws.com/'
  apiUrl = "https://endpoint.dazhboards.com/"
  ReviewApi = 'https://reviews.dazhboards.com/api/'
  url = 'https://app.dazhboards.com/'
  chatbot_api = 'https://bot.dazhboards.com/'
}
const environment = {
  adminRoleId: '64b152a909d268f038611929',
  chatbot_api: chatbot_api,
  api: apiUrl,
  foreCastingApi: 'http://3.92.142.225:8087/',
  imageApi: ImageUrl,
  review_api: ReviewApi,
  bookingApi: apiUrl,
  url: url,
  googleAPIKey: process.env.REACT_APP_GOOGLEAPIKEY || 'AIzaSyCWigOyC23DW0Ov9zUUQJi37hQ3lOW8LB0',
  userRoleId: '64b15102b14de6c28838f7d2',
  partnerRole: '64b15102b14de6c28838f7d2',
  SubRolePartner: '65d305b193650817f7620002',
  fbAppId: process.env.REACT_APP_FBAPPID || '687148143612037',
  fbConfig_id: process.env.REACT_APP_FBCONFIGID || '1445414022852988',
  tinyKey: process.env.REACT_APP_TINYKEY || '1caoqocqc05l8re1m7vm2ck0f4lbaqvg7w9k6a7wr7pkrbhv',
  map_api_key: process.env.REACT_APP_MAPAPIKEY || 'AIzaSyDXcaa81DeBgUyniq30GpZ5pYI7oDneKlE',
  resellerTypeId: '64b23b7d690d1d5f7ae76102',
  productTypeId: '64a7d198fa039f179c0320ca',
  OPENAI_API_KEY: process.env.REACT_APP_OPENAI_API_KEY || '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',
  sentry_dsn: process.env.REACT_APP_SENTRYDSN || 'https://75b90e4a40c9092c4db4a88bd024158d@o4507949933920256.ingest.us.sentry.io/4507969670742016',
  //bokun app
  bokunAppApiKey: "231a25cb2fb648d09f4df81dbedbee52",
  bokunAppSecretKey: "64677d428c2b41f98f04c4fe699e8530"
};

export default environment;