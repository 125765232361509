import React, { useState } from 'react'
import Step2 from './Step2'
import FinalStep from './FinalStep'
import DetailsSaver from './DetailsSaver'
import { SMTPconstants } from '../GetStarted'
import { IoMdSettings } from 'react-icons/io'
import { Link } from 'react-router-dom'

export default function GoogleConnection({ setProvider }) {
    const [steps, setSteps] = useState(1)
    const StepFirst = () => {
        return (
            <>
                <div className="flex justify-center items-center  mt-10">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                        {/* Go back link */}
                        <div className="flex items-center mb-4 cursor-pointer" onClick={() => setProvider(null)}>
                            <i className="text-gray-700">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-left text-dark icon-xs"
                                    style={{ strokeWidth: '3px', marginTop: '-2px' }}
                                >
                                    <g>
                                        <polyline points="15 18 9 12 15 6" />
                                    </g>
                                </svg>
                            </i>
                            <p className="ml-2 text-sm text-gray-600">Select another provider</p>
                        </div>

                        <hr className="my-4" />

                        {/* Google Account connection section */}
                        <div className="flex items-center mb-3">
                            <img
                                src="/assets/img/smtp/g1.svg"
                                alt="Provider"
                                className="w-10"
                            />
                            <div className="ml-3">
                                <h5 className="text-xl font-semibold">Connect Your Google Account</h5>
                                <p className="text-sm text-gray-500">Gmail / G-Suite</p>
                            </div>
                        </div>

                        {/* Instructions */}
                        <p className="text-center text-lg pt-4">
                            First, let's <span className="text-primary   font-semibold">enable IMAP</span> access
                            for your Google account.
                        </p>
                        <hr className="my-4" />

                        <ol className="list-decimal pl-6">
                            <li className="mt-3 mb-3">On your computer, open Gmail.</li>
                            <li className="mb-3 ">
                                <div className='flex gap-1 items-center' >
                                Click the{' '}
                              <span className='px-1'>  <IoMdSettings className='' /></span>
                                gear icon in the top right corner.
                                </div>
                            </li>
                            <li className="mb-3">Click <span className="font-semibold text-gray-800">All Settings</span>.</li>
                            <li className="mb-3">
                                Click the{' '}
                                <a href="https://mail.google.com/mail/u/0/#settings/fwdandpop" target="_blank" className="text-primary   font-semibold">
                                    Forwarding and POP/IMAP
                                </a>{' '}
                                tab.
                            </li>
                            <li className="mb-3">In the "IMAP access" section, select <span className="font-semibold text-gray-800">Enable IMAP</span>.</li>
                            <li>Click <span className="font-semibold text-gray-800">Save Changes</span>.</li>
                        </ol>

                        {/* Help link */}
                        <div className="text-center mt-4">
                            <Link
                                to="/how-to-enable-imap-on-google-accounts"
                                target="_blank"
                                className="text-gray-600 flex items-center justify-center"
                            >
                                Show me how
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    height="25"
                                    width="25"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-2"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                </svg>
                            </Link>
                        </div>

                        {/* Confirmation button */}
                        <div className="text-center mt-6">
                            <button className="bg-primary text-white px-6 py-2 rounded-full text-lg font-semibold focus:outline-none hover:bg-blue-700" onClick={() => setSteps(2)}>
                                Yes, IMAP has been enabled
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    height="25"
                                    width="25"
                                    className="ml-2 inline-block"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </>


        )
    }
    const StepIdentifier = () => {
        switch (steps) {
            case 1:
                return StepFirst()
            case 2:
                return <Step2 setSteps={setSteps} setProvider={setProvider} />
            case 3:
                return <FinalStep setSteps={setSteps} setProvider={setProvider} />
            case 4:
                return <DetailsSaver setSteps={setSteps} setProvider={setProvider} />
            default:
                return null
        }
    }
    return (
        <>
            {StepIdentifier()}

        </>
    )
}
