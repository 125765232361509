import React, { useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { MdKeyboardArrowRight } from 'react-icons/md'

export default function OutlookConnection({ setProvider, constants }) {

  return (
    <>
      <div className="bg-white shadow-md mt-5">



        {/* <div className="flex items-center p-4" >
          <span className="cursor-pointer text-gray-600 flex items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left mr-2"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
            <h6 className="text-lg font-semibold">Back</h6>
          </span>
        </div> */}

      </div>
      <div className="max-w-3xl mx-auto">




        {/* Go back link */}
        <div className="flex items-center mb-4 cursor-pointer" onClick={() => setProvider(null)}>
          <i className="text-gray-700">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left text-dark icon-xs"
              style={{ strokeWidth: '3px', marginTop: '-2px' }}
            >
              <g>
                <polyline points="15 18 9 12 15 6" />
              </g>
            </svg>
          </i>
          <p className="ml-2 text-sm text-gray-600">Select another provider</p>
        </div>

        <hr className="my-4" />


        {/* Google Account connection section */}
        <div className="flex items-center mb-3">
          <img
            src="/assets/img/smtp/g2.svg"
            alt="Provider"
            className="w-10"
          />
          <div className="ml-3">
            <h5 className="text-xl font-semibold">Connect Your Microsoft Account
            </h5>
            <p className="text-sm text-gray-500">Office 365 / Outlook

            </p>
          </div>
        </div>


        <div class="bg-white shadow-md rounded-lg p-8">
          <h1 class="text-2xl font-bold mb-4">Steps to Create an App Password</h1>
          <ol class="list-decimal list-outside pl-5 space-y-4">
            <li>
              <p>
                Go to the
                <a href="https://account.microsoft.com/security" target="_blank"
                  class="text-primary   hover:underline"> Microsoft account security page </a>
                and sign in.
              </p>
            </li>
            <li>
              <p>
                Under <strong>Additional Security Options</strong>, click on
                <strong> Create a new app password</strong>.
              </p>
            </li>
            <li>
              <p>
                Use the app password generated (instead of your regular email password)
                in your application.
              </p>
            </li>
          </ol>
        </div>

        <div className="grid grid-cols-12 gap-4 mt-4 w-full">
          <div className="col-span-12 md:col-span-6">
            <div className="border-2 bordedr-[#efefef] bg-white shadow-[#efefef_0px_0px_10px_0px] p-6 rounded-xl">
              <h3 className="text-[17px] font-[700] text-center"> Microsoft accounts purchased directly from Microsoft </h3>
              <ol class="list-decimal list-outside pl-5 overflow-y-scroll h-52">
                <li class="mt-4 mb-3">
                  On your computer, log in to your
                  <span class="text-gray-800 font-semibold">
                    <a href="https://admin.microsoft.com/#/homepage" target="_blank" class="text-primary   hover:underline"> Microsoft Admin center</a>
                  </span>.
                </li>
                <li class="mb-3">
                  Open
                  <span class="text-gray-800 font-semibold">
                    <a href="https://admin.microsoft.com/#/users" target="_blank" class="text-primary   hover:underline"> Active Users</a>
                  </span>.
                </li>
                <li class="mb-3">
                  In the side window, click on
                  <span class="text-gray-800 font-semibold"> Mail</span> tab, and then on
                  <span class="text-gray-800 font-semibold"> Manage email apps</span>.
                </li>
                <li class="mb-3">
                  Check the
                  <span class="text-gray-800 font-semibold"> Authenticated SMTP</span> box and make sure
                  <span class="text-gray-800 font-semibold"> IMAP</span> is checked too.
                </li>
                <li class="mb-3">
                  Click
                  <span class="text-gray-800 font-semibold"> Save Changes</span>.
                </li>
                <li>
                  Wait for one hour and connect your account to Instantly.
                </li>
              </ol>

              <div className="">
                <a className="flex items-center pt-5 justify-center text-[#8492a6]">Show me how
                  <MdKeyboardArrowRight className="ml-2" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-span-12 md:col-span-6">
            <div className="border-2 bordedr-[#efefef] bg-white shadow-[#efefef_0px_0px_10px_0px] p-6 rounded-xl">
              <h3 className="text-[17px] font-[700] text-center">Microsoft accounts purchased from GoDaddy

              </h3>
              <ol class="list-decimal list-outside pl-5 overflow-y-scroll h-52">
                <li class="mt-4 mb-3">
                  On your computer, log in to your
                  <span class="text-gray-800 font-semibold">
                    <a href="http://godaddy.com/" target="_blank" class="text-primary   hover:underline"> GoDaddy </a>
                  </span>
                  account.
                </li>
                <li class="mb-3">
                  Go to <span class="text-gray-800 font-semibold"> My Products</span> page.
                </li>
                <li class="mb-3">
                  Scroll down and go to <span class="text-gray-800 font-semibold"> Email and Office</span> section.
                </li>
                <li class="mb-3">
                  Click <span class="text-gray-800 font-semibold"> Manage All</span>.
                </li>
                <li class="mb-3">
                  Find the user you want to enable SMTP for and click
                  <span class="text-gray-800 font-semibold"> Manage</span>.
                </li>
                <li class="mb-3">
                  Scroll down, click on
                  <span class="text-gray-800 font-semibold"> Advanced Settings</span>.
                </li>
                <li class="mb-3">
                  Click on
                  <span class="text-gray-800 font-semibold"> SMTP Authentication</span>
                  - the button will turn from gray to green.
                </li>
                <li>
                  Wait for one hour and proceed to connect the account to Instantly.
                </li>
              </ol>


              <div className="">
                <a className="flex items-center pt-5 justify-center text-[#8492a6]">Show me how
                  <MdKeyboardArrowRight className="ml-2" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-12 mt-4">
            <button
              onClick={e => setProvider(constants?.others)}
              className="flex items-center m-auto rounded-md bg-primary text-[15px] font-[700] text-[#fff] py-2 px-3">
              Yes, SMTP has been enabled
            </button>
          </div>

        </div>

      </div>


    </>
  )
}


