import React from "react";
// import { GoDotFill } from "react-icons/go";

const Badge = ({ label }) => {
  const isLifetimeData = label.toLowerCase()?.includes('life time');

  return (
    <div
    className=""

      // style={{
      //   backgroundColor: isLifetimeData ? '#dff4d5' : '#DDE9FF', // Darker shade of blue for lifetime data
      //   border: `1px solid ${isLifetimeData ? 'rgba(57,124,246,0.10)' : 'rgba(57,124,246,0.10)'}`,
      // }}
    >
      <div
        // className="2xl:w-1.5 2xl:h-1.5 w-[5px] h-[5px] shrink-0"
        // style={{ backgroundColor: isLifetimeData ? '#7cc15b' : '#397CF6' }} // Same shade of blue for the dot
      />
      <p className="{`tracking-custom 2xl:whitespace-nowrap text-sm font-normal mb-2 ${isLifetimeData ? 'text-[#dff4d5]' : 'text-[#DDE9FF]'}`}">{label}</p>
    </div>
  );
};



export default Badge;
