import React, { useState } from 'react';
import GoogleConnection from './Google/GoogleConnection';
import SmtpSetupForm from './SmtpConnection';
import ConnectionSetup from './ConnectionSetup';
import Layout from '../../components/global/layout';
import NavTabs from '../../components/global/NavTabs';
import { BookingTabs } from '../../utils/constants';
import OutlookConnection from './Microsoft/MicrosoftConnection';

export const SMTPconstants = {
    google: 'google',
    outlook: 'outlook',
    others: 'others',
}
export default function GetStarted() {
    const [Provider, setProvider] = useState(null)
    const constants = SMTPconstants
    return (
        <>
            <Layout>
                <NavTabs tabsData={BookingTabs} />
                {Provider == null ? <ConnectionSetup setProvider={setProvider} Provider={Provider} constants={constants} /> : null}
                {Provider == constants.google ? <GoogleConnection setProvider={setProvider} /> : null}
                {Provider == constants.outlook ? <OutlookConnection setProvider={setProvider} constants={constants} /> : null}
                {Provider == constants.others ? <SmtpSetupForm setProvider={setProvider} /> : null}
            </Layout>
        </>
    );
}
