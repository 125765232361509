import React, { useEffect, useState } from 'react';
import NavTabs from '../../components/global/NavTabs';
import { BookingTabs } from '../../utils/constants';
import Layout from '../../components/global/layout';
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import { useSelector } from 'react-redux';

const SmtpSetupForm = ({ setProvider }) => {
    const user = useSelector(state => state.user)
    const [formData, setFormData] = useState({
        service: 'Others',
        host: '',
        username: '',
        password: '',
        port: '',
        domains: [''],
    });
    const DataGetter = () => {
        loader(true);
        ApiClient.get(`api/smtp/listing?id=${user?.id || user?._id}`).then(res => {
            if (res.success) {
                if (res?.data?.length) {
                    setFormData(res?.data[0])
                }
            }
            loader(false);
        })
    }

    useEffect(() => {
        // DataGetter()
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'domains') {
            const domains = value.split(',').map((domain) => domain.trim());
            setFormData({ ...formData, domains });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit the form data to your server
        let url = 'api/create/smtp'
        let method = 'post';
        if (formData?.id) {
            url = 'api/update/smtp'
            method = 'put'
        }
        ApiClient.allApi(url, formData, method).then(res => {
            if (res.success) {
                setProvider(null)
                swal({
                    icon: 'success',
                    text: 'SMTP Configuration saved successfully',
                    className: 'smallmodal'
                })
            }
        });

    };

    return (
        <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <div className="flex items-center justify-between gap-1 mb-6">
            <h4 className="text-2xl font-semibold text-gray-800">
                SMTP Server Setup
            </h4>
        </div>
        <h2 className="text-xl font-medium text-center mb-6">Configure your SMTP settings</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData?.name}
                    onChange={handleChange}
                    required
                    className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                    placeholder="e.g., smtp.gmail.com"
                />
            </div>
    
            <div className="mb-4">
                <label htmlFor="host" className="block text-sm font-medium text-gray-700">Host</label>
                <input
                    type="text"
                    id="host"
                    name="host"
                    value={formData.host}
                    onChange={handleChange}
                    required
                    className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                    placeholder="e.g., smtp.gmail.com"
                />
            </div>
    
            <div className="mb-4">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                    placeholder="Your email address"
                />
            </div>
    
            <div className="mb-4">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                    placeholder="Your email password"
                />
            </div>
    
            <div className="mb-4">
                <label htmlFor="port" className="block text-sm font-medium text-gray-700">Port</label>
                <input
                    type="number"
                    id="port"
                    name="port"
                    min={1}
                    value={formData.port}
                    onChange={handleChange}
                    required
                    className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                    placeholder="Port number"
                />
            </div>
    
            <div className="mb-4">
                <label htmlFor="domains" className="block text-sm font-medium text-gray-700">Domains</label>
                <input
                    type="text"
                    id="domains"
                    name="domains"
                    value={formData.domains.join(', ')}
                    onChange={handleChange}
                    required
                    className="mt-1 p-3 w-full border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                    placeholder="Comma-separated list of domains"
                />
            </div>
    
            <div className="flex justify-end mt-6 space-x-4">
                <button
                    type="button"
                    onClick={() => setProvider(null)}
                    className="bg-white px-4 py-2 text-sm font-normal text-primary   rounded-lg shadow hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 focus:ring-primary disabled:bg-gray-200 disabled:cursor-not-allowed"
                >
                    Back
                </button>
                <button
                    type="submit"
                    className="bg-primary px-4 py-2 text-sm font-normal text-white rounded-lg shadow hover:bg-primary-dark transition-all focus:ring-2 focus:ring-primary disabled:bg-primary-light disabled:cursor-not-allowed"
                >
                    Save SMTP Configuration
                </button>
            </div>
        </form>
    </div>
    
    );
};

export default SmtpSetupForm;
