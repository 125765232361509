export const PlanModules = {
    "Omni-Channel": ["chatbot", "chat", "whatsapp", "Widget-settings", "review-insights", "review-management", "reviews-settings",],
    "Customer Relations": ["chatbot", "chat", "whatsapp", "Widget-settings"],
    "Reviews": ["review-insights", "review-management", "reviews-settings",],
    "Sales": ["sales-insights", "sales-data", "customer-insights", "customer-data", "historical-data", "refund/cancel-data", "Waiver-templates", "waiver-settings", "waiver-database"],
    "Waivers": ["Waiver-templates", "waiver-settings", "waiver-database"],
    "Products": ["product-insights", "product-data", "deleted-products", "historical-data", "cancellation-policies", "update-logs", "settings", "budget", "costing",],
    "Reseller": ["resellers-insights", "list-of-resellers", "list-of-invoices", "invoice-templates", "contract-templates"],
    "Profitability": ["insights", "profit-analysis-month", "profit-analysis-date", "profitability-data"],
    "Marketing": ["inbox", "default-commission", "products-banners", "general-banners", "affiliate-request", "current-affiliate", "affiliate-dataBase", "marketing-insights", "forecasting", "google-analytics-summary", "google-ads", "facebook-ads", "instagram-analysis", "manage-google-tags",],
    "Settings": ["company-details", "partners", "email-list", "email-templates", "data-connection", "booking-page-settings", "users",]

}
