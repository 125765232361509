// FloatingChatbot.js
import React, { useEffect, useRef, useState } from 'react';
import { TbArrowsDiagonal } from 'react-icons/tb';
import { WiStars } from 'react-icons/wi';
import { BsThreeDots } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import loader from '../../../methods/loader';
import ApiClient from '../../../methods/api/apiClient';
import { useSelector } from 'react-redux';
import environment from '../../../environment';
import { BiTrash } from 'react-icons/bi';
import moment from 'moment';
import parse from 'html-react-parser'
import methodModel from '../../../methods/methods';

const FloatingChatbot = ({ isVisible, onClose }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const user = useSelector(state => state.user);
  const chatBoxRef = useRef(null);

  const handleExpand = () => {
    setIsExpanded(prev => !prev);
  };
  const [AnalysisBotData, setAnalysisBotData] = useState([]);
  const GetAnalysisBotData = (loaderData = false) => {
    if ((user?.id || user?._id)) {

      // return
      if (loaderData) {
        loader(true)
      }
      ApiClient.post(`api/analysis-chat-list/`, { supplier_id: user?._id || user?.id }, environment.chatbot_api).then(res => {
        if (res.success) {
          setAnalysisBotData(res?.data)
        }
        loader(false)
      })
    }
  }
  useEffect(() => {
    if (isVisible) {
      GetAnalysisBotData()
    }
  }, [isVisible])
  const InputRef = useRef();
  const [AILoader, setAILoader] = useState(false);
  const ChatScroll = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }
  useEffect(() => {
    ChatScroll()
  }, [AnalysisBotData])
  const textPatternFormatting = (text) => {
    const pattern = /^(###\s*(.*$))|\*\*\[(.+?)\]\((.+?)\)\*\*|\*\*(.*?)\*\*|_(.*?)_|`(.*?)`|\[(.+?)\]\((.+?)\)|(https?:\/\/[^\s]+)|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)|(\+\d{10,15})|\[Book Here\]\n\((.+?)\)/gm;

    if (text?.toLowerCase() === 'want to talk live agent') {
      return 'Would you like to connect with a live agent for further assistance?';
    }

    return text.replace(pattern, (match, header, headerText, boldLinkText, boldLinkUrl, boldText, italicText, codeText, linkText, linkUrl, directLink, email, phoneNumber, bookHereUrl) => {
      if (headerText) {
        // Handles "### Header"
        return `<h3>${headerText.trim()}</h3>`;
      } else if (boldLinkText && boldLinkUrl) {
        // Handles "**[text](url)**"
        return `<b><a href="${boldLinkUrl}" target="_blank" style="color: blue; text-decoration: underline;">${boldLinkText}</a></b>`;
      } else if (boldText) {
        // Handles "**bold text**"
        return `<b>${boldText}</b>`;
      } else if (italicText) {
        // Handles "_italic text_"
        return `<i>${italicText}</i>`;
      } else if (codeText) {
        // Handles "`inline code`"
        return `<code>${codeText}</code>`;
      } else if (linkText && linkUrl) {
        // Handles "[link text](url)"
        return `<a href="${linkUrl}" target="_blank" style="color: blue; text-decoration: underline;">${linkText}</a>`;
      } else if (email) {
        // Handles email links
        return `<a href="mailto:${email}" target="_blank" style="color: blue; text-decoration: underline;">${email}</a>`;
      } else if (phoneNumber) {
        // Handles phone numbers
        return `<a href="tel:${phoneNumber}" target="_blank" style="color: blue; text-decoration: underline;">${phoneNumber}</a>`;
      } else if (bookHereUrl) {
        // Handles "[Book Here]\n(link_url)"
        return `<a href="${bookHereUrl}" target="_blank" style="color: blue; text-decoration: underline;">Book Here</a>`;
      }
      return match; // Fallback for unmatched cases
    }).replace(/\n/g, '<br>'); // Converts line breaks to <br>
  };

  const ChatSender = (e) => {
    e.preventDefault();

    if (!InputRef.current?.value
    ) {
      return
    }
    let data = InputRef.current.value
    setAnalysisBotData(prev => ([...prev, { sent: new Date(), question: data }]));
    ChatScroll()
    setAILoader(true)

    InputRef.current.value = ''
    ApiClient.post('api/analysis-chat/', {
      "question": data,
      "supplier_id": user?.id || user?._id
    }, environment.chatbot_api).then(res => {
      setAILoader(false)
      if (res?.message) {
        setAnalysisBotData(prev => ([...prev, { sent: new Date(), answer: res?.message }]))
        ChatScroll()
      }
    })
  }

  const ClearChatHandler = (e) => {
    e.preventDefault();
    ApiClient.post(`api/analysis-clear-chat/`, { supplier_id: user?.id || user?._id }, environment.chatbot_api).then(res => {
      if (res?.success) {
        GetAnalysisBotData(true)
      }
    })
  }
  return (
    <div>
      {isVisible && (
        <div className={`fixed bottom-1 right-0 p-3 ml-auto transition-all w-[calc(100%_-_380px)]  xl:w-[calc(100%_-_280px)] z-50 ${isExpanded ? '' : 'w-full max-w-[600px]'}`}
        >
          <div className={`h-full relative transform rounded-large text-left transition-all w-full shadow-xl ${isExpanded ? 'w-full' : ''}`}>
            <div className={`h-full bg-white border-primary border rounded-large ${isExpanded ? '' : ''}`}>
              <div className="mt-3 text-center sm:mt-0 sm:text-left sm:w-full">
                <div className="flex justify-between gap-5 shadow-[0px_1px_2px_1px_#12376914] p-5 rounded-t-large">
                  <div className="flex gap-2 items-center">
                    <img src="/assets/img/logo-short.png" alt="logo" className="w-5 h-5" />
                    <h3 className="text-lg font-semibold text-typo">Dazhboards</h3>
                    <button className="hover:bg-primary hover:text-white transition-all items-center flex text-[#344054] font-medium text-xs w-10 h-[22px] py-0.5 px-1.5 border border-[#D0D5DD] rounded-md">
                      <WiStars className="text-[#8675F4] size-3 mr-[3px]" />
                      AI
                    </button>
                    <button onClick={e => ClearChatHandler(e)} className="hover:bg-red-400 hover:text-white transition-all items-center flex text-[#344054] font-xxl text-xs px-1 h-[22px] py-0.5  border border-[#D0D5DD] rounded-md">
                      <BiTrash className="text-[#8675F4] size-3 mr-[3px]" />
                    </button>
                  </div>
                  <div className="flex gap-2">
                    <button type="button" className="p-1 hover:bg-primary group hover:text-white transition-all rounded-lg" onClick={handleExpand}>
                      <TbArrowsDiagonal className="size-5 text-[#7E8B99] hover:text-white" />
                    </button>
                    <button type="button" onClick={onClose} className="hover:text-red-500 transition-all rounded-lg">
                      <RxCross2 className="size-5 text-typo hover:text-red-500" />
                    </button>
                  </div>
                </div>
                <div className="flex justify-center items-center flex-col py-8">
                  <img src="/assets/img/logo-short.png" alt="logo" className="size-[56px] mb-4" />
                  <h2 className="text-lg font-semibold text-typo mb-1">Dazhboards AI Support</h2>
                  {/* {isExpanded && (
                  )} */}
                </div>
                <div className="px-5 h-[300px] xl:h-[400px] scrollbar overflow-auto" id='chat-box' ref={chatBoxRef}>
                  {AnalysisBotData?.map((item) => (<>
                    {item?.question && <div className={`ml-auto w-full ${isExpanded ? 'max-w-[1009px]' : 'max-w-[672px]'}`}>
                      <p className="bg-[#F5F6FA] py-2.5 px-3.5 mb-2 rounded-lg rounded-tl-none text-sm text-[#344054]">
                        {item?.question}
                      </p>
                      <span className="block p-1 text-xs font-normal text-[#475467] text-right">{methodModel.MomentFormat(item?.sent, 'hh:mm a')}</span>
                    </div>}
                    {item?.answer && <div className={`w-full ${isExpanded ? 'max-w-[1009px]' : 'max-w-[672px]'}`}>
                      <p className="bg-[#F5F6FA] py-2.5 px-3.5 mb-2 rounded-lg rounded-tl-none text-sm text-[#344054]">
                        {parse(`${textPatternFormatting(item?.answer)}`)}
                      </p>
                      <span className="block p-1 text-xs font-normal text-[#475467]">{methodModel.MomentFormat(item?.received, 'hh:mm a')}</span>
                    </div>}

                  </>))}
                  {AILoader && <div className="p-5 w-full flex gap-2 items-center">
                    <WiStars className="text-[#8675F4] size-4" />
                    <p className="text-sm italic font-normal text-[#8675F4]">AI generating</p>
                    <BsThreeDots className="text-[#8675F4]" />
                  </div>}
                  <div id='chatScroller'></div>

                </div>


                <form onSubmit={ChatSender}>
                  <div className="p-5 border-t border-[#EAECF0] flex gap-3">
                    <input
                      type="text"
                      ref={InputRef}
                      className="placeholder:font-light w-full h-11 shadow-box border !border-grey py-2.5 px-3.5 rounded-large text-sm leading-6 text-gray-900 focus:outline-primary"
                      placeholder="Send Message"
                    />
                    <button type="submit" className="w-11 h-11 shrink-0 bg-primary hover:opacity-80 rounded-extra_large flex items-center justify-center">
                      <img src="/assets/img/svgs/send.svg" alt="" className="w-5 h-5" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingChatbot;
