import React from 'react'

function PaymentNew() {
  return (
    <div className='container mx-auto py-16 px-6 overflow-auto'>
        <div className='grid grid-cols-12'>
            <div className='col-span-12 md:col-span-7 xl:col-span-8'>
                <div className='payment-inners xl:w-2/3'>
                    <h5 className='text-[24px] text-[#151618] font-medium'>Choose payment method </h5>
                    <div className='bg-[#E2F6EB] inline-flex rounded-xl mt-6'>
                        <div className='flex gap-4 items-center px-4 py-3'>
                            <img className='' src='assets/front/cart/lock.svg' alt='lock'/>
                            <p className='text-[14px] font-regular'>This is a secure and SSL encrypted payment. Your credit card details are safe.</p>
                        </div>
                    </div>

                    <div className='all_radios flex flex-col gap-6 mt-6 mb-6'>

                  
                        <div className='visa_start'>

                        <div className='flex gap-4 items-center mb-6 '>
                            <div className='visa_bank'>
                                <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3  !rounded-large">
                                    <input
                                        className="h-6 w-6 checked:!ring-primary disabled checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary  "
                                        type="radio"
                                        value=""
                                    
                                        checked="checked"
                                        
                                        name="showLogo2"
                                    />
                                
                                </label>

                            </div>
                            <img className='h-8 object-contain' src='assets/front/cart/visa-black.png'  alt='visa'/>
                        </div>

                        <div className='border border-[#D6D6D8] px-6 py-2 h-[48px] rounded-2xl mb-4'>
                            <div className='flex h-full items-center gap-4'>
                                <img className='' src='assets/front/cart/card.svg' alt='lock'/>
                                <input className='w-full bg-transparent focus:outline-0' placeholder='Card Number' />
                            </div>
                        </div>

                        <div className='border border-[#D6D6D8] px-6 py-2 h-[48px] rounded-2xl mb-4'>
                            <div className='flex h-full items-center gap-4'>
                                <input className='w-full bg-transparent focus:outline-0' placeholder='Card Holder' />
                            </div>
                        </div>

                        <div className='flex items-center gap-4 w-full'>
                        <div className='border border-[#D6D6D8] px-6 py-2 h-[48px] w-1/2 rounded-2xl mb-4'>
                            <div className='flex h-full items-center gap-4'>
                               
                                <input className='w-full bg-transparent focus:outline-0' placeholder='Expiry' />
                            </div>
                        </div>

                        <div className='border border-[#D6D6D8] px-6 py-2 h-[48px] w-1/2 rounded-2xl mb-4'>
                            <div className='flex h-full items-center gap-4'>
                                <input className='w-full bg-transparent focus:outline-0' placeholder='CVV' />
                            </div>
                        </div>
                        </div>



                        </div>


                        <div className='flex gap-4 items-center  '>
                            <div className='visa_bank'>
                                <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3  !rounded-large">
                                    <input
                                        className="h-6 w-6 checked:!ring-primary disabled checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary  "
                                        type="radio"
                                        value=""
                                    
                                        checked="checked"
                                        
                                        name="showLogo2"
                                    />
                                
                                </label>

                            </div>
                            <img className='h-8 object-contain' src='assets/front/cart/paypal-blue.png'  alt='visa'/>
                        </div>

                        <div className='flex gap-4 items-center  '>
                            <div className='visa_bank'>
                                <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3  !rounded-large">
                                    <input
                                        className="h-6 w-6 checked:!ring-primary disabled checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary  "
                                        type="radio"
                                        value=""
                                    
                                        checked="checked"
                                        
                                        name="showLogo2"
                                    />
                                
                                </label>

                            </div>
                            <img className='h-8 object-contain' src='assets/front/cart/google-black.png'  alt='visa'/>
                        </div>

                        <div className='flex gap-4 items-center  '>
                            <div className='visa_bank'>
                                <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3  !rounded-large">
                                    <input
                                        className="h-6 w-6 checked:!ring-primary disabled checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary  "
                                        type="radio"
                                        value=""
                                    
                                        checked="checked"
                                        
                                        name="showLogo2"
                                    />
                                
                                </label>

                            </div>
                            <img className='h-8 object-contain' src='assets/front/cart/apple-black.png'  alt='visa'/>
                        </div>


                    </div>


                  <div className='py-4'>
                    <p className='text-[12px] font-regular text-[#151618]'>
                        By selecting the button below, I agree to the By selecting the button below, I agree to the By selecting the button below, I agree to the  By selecting the button below, I agree to the 
                    </p>
                  </div>


                  <div className='pt-4'>
                    <button className='bg-[#9837F1] text-white px-4 py-2 rounded-lg text-[16px] font-semibold w-full'>Confirm payment</button>
                  </div>

                </div>
            </div>
            <div className='col-span-12 md:col-span-5 xl:col-span-4'>
                    <div className='lists_packages'>
                        <div className='text-right'>
                            <div className='flex flex-col border border-[#d9d9d9] rounded-2xl'>
                                <div className='flex items-center justify-between flex-wrap px-6 py-4 xl:py-6 border-b border-[#d9d9d9]'>
                                    <p className='text-[20px] font-bold text-[#9837F1]'>Sydney</p>
                                    <div className='text-right'>
                                        <p className='text-[#151618] text-[18px] font-semibold'>AUD $3,500</p>
                                        <p className='text-[#959EAD] text-[15px] font-regular'>save AUD $26</p>
                                    </div>
                                </div>

                                <div className='flex items-center justify-between flex-wrap px-6 py-4 xl:py-6 border-b border-[#d9d9d9]'>
                                    <p className='text-[20px] font-bold text-[#9837F1]'>Sydney</p>
                                    <div className='text-right'>
                                        <p className='text-[#151618] text-[18px] font-semibold'>AUD $3,500</p>
                                        <p className='text-[#959EAD] text-[15px] font-regular'>save AUD $26</p>
                                    </div>
                                </div>


                                <div className='flex items-center justify-between flex-wrap px-6 py-4 xl:py-6 border-b border-[#d9d9d9]'>
                                    <p className='text-[20px] font-bold text-[#9837F1]'>Sydney</p>
                                    <div className='text-right'>
                                        <p className='text-[#151618] text-[18px] font-semibold'>AUD $3,500</p>
                                        <p className='text-[#959EAD] text-[15px] font-regular'>save AUD $26</p>
                                    </div>
                                </div>


                                <div className='flex items-center justify-between flex-wrap px-6 py-4 xl:py-6 border-b border-[#d9d9d9]'>
                                    <p className='text-[20px] font-bold text-[#9837F1]'>Sydney</p>
                                    <div className='text-right'>
                                        <p className='text-[#151618] text-[18px] font-semibold'>AUD $3,500</p>
                                        <p className='text-[#959EAD] text-[15px] font-regular'>save AUD $26</p>
                                    </div>
                                </div>

                                <div className='flex items-center justify-between flex-wrap px-6 py-4 xl:py-6 border-b border-[#d9d9d9]'>
                                    <p className='text-[20px] font-bold text-[#9837F1]'>Sydney</p>

                                    

                                    <div className='text-right'>
                                        <p className='text-[#151618] text-[18px] font-semibold'>AUD $3,500</p>
                                        <p className='text-[#959EAD] text-[15px] font-regular'>save AUD $26</p>
                                    </div>
                                </div>


                                <div className='flex items-center justify-between flex-wrap px-6 py-4 xl:py-8 '>
                                    <p className='text-[20px] font-bold text-[#151618]'>Total Costs:</p>
                                    <div className='text-right'>
                                        
                                        <p className='text-[#959EAD] text-[15px] font-regular'>save AUD $26</p>
                                        <p className='text-[#151618] text-[20px] font-bold'>AUD $3,500</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default PaymentNew
