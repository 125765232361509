import React, { useEffect, useState } from 'react'
import { CgDanger } from 'react-icons/cg'
import { IoIosArrowDown, IoMdClose } from 'react-icons/io';
import { IoCheckmark, IoCloseOutline, IoLocationOutline } from 'react-icons/io5';
import { RiSearchLine, RiShoppingCart2Line } from 'react-icons/ri';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { GoClock, GoHeart, GoHeartFill, GoPlus } from 'react-icons/go';
import methodModel from '../../methods/methods';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import parse from 'html-react-parser'
import environment from '../../environment';
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import BookingCalendar from './BookingCalender';
import { LuCalendarDays } from 'react-icons/lu';
import { FaStar } from 'react-icons/fa';
import DOMPurify from 'dompurify';




export default function Product() {
    const ProductId = methodModel.getPrams('productId');
    const [ProductData, setProductData] = useState({});
    const [CurrentViewImage, setCurrentViewImage] = useState(null);
    const [PickupLocations, setPickupLocations] = useState([]);
    const [SelectedPickupLocation, setSelectedPickupLocation] = useState({ latitude: '40.697370922272775', longitude: '-74.14448655475034', address: 'New York' })
    const [ProductState, setProductState] = useState({ cart: false })
    useEffect(() => {
        if (ProductId) {
            loader(true)
            ApiClient.get(`api/booking/product/detail?id=${ProductId}`).then(res => {
                if (res?.success) {
                    setProductData(res?.data);
                    setPickupLocations(res?.data?.pickupList?.pickupLocations);
                    setCurrentViewImage(res?.data?.images && res?.data?.images[0] && res?.data?.images[0]?.itemUrl)
                }
                loader(false)
            })
        }
    }, [ProductId]);

    const PickupLocationSearcher = (data) => {
        const ProductListData = ProductData?.pickupList?.pickupLocations;
        const name = data?.toLowerCase();
        const FinalData = ProductListData?.filter((itm) => itm?.address?.toLowerCase()?.includes(name))
        setPickupLocations(FinalData)

    }
    const generateSimpleMapUrl = () => {
        if (!SelectedPickupLocation) return "";


        // Fallback to using the address if coordinates are unavailable
        if (SelectedPickupLocation.address) {
            const encodedAddress = encodeURIComponent(SelectedPickupLocation.address);
            return `https://www.google.com/maps/embed/v1/place?key=${environment.map_api_key}&q=${encodedAddress}`;
        }

        return ""; // Return empty string if neither coordinates nor address are available
    };


    let [isOpen, setIsOpen] = useState(false)

    function open() {
        setIsOpen(true)
        setProductState({ ...ProductState, cart: false })
    }

    function close() {
        setIsOpen(false)
    }

    const [CartData, setCartData] = useState(JSON.parse(localStorage.getItem('cart_data')) || []);



    return (
        <div className='container mx-auto py-16 px-6 overflow-auto h-screen'>






            <div className='flex justify-end items-center mb-6'>
                <div className="relative group">

                    <RiShoppingCart2Line className='text-2xl cursor-pointer' />
                    {CartData?.length ? <p class="text-[10px] absolute -right-3 -top-1 bg-primary cursor-pointer h-5 w-5 p-1 flex items-center justify-center text-white rounded-full"><span>{CartData?.length}</span></p> : null}


                    <div className=" absolute top-5 z-50 right-0 visible_onhover w-72 shadow-sm bg-white border px-4 py-2 border-gray-100 rounded-xl hidden group-hover:block">
                        <div className='flex flex-col gap-4'>
                            {CartData?.map((item, index) => (<div className='border-b border-gray-300 pb-4'>
                                <div className='flex justify-between gap-4'>
                                    <div className=''>
                                        <p>{item?.name}</p>
                                        <p>Date: <span>{item?.date}</span></p>
                                    </div>
                                    <p>
                                        <IoMdClose onClick={e => {
                                            const data = JSON.parse(localStorage.getItem('cart_data')) || []
                                            let filteredData = data?.filter((_, inde) => index != inde)
                                            localStorage.setItem('cart_data', JSON.stringify(filteredData))
                                            setCartData(filteredData);
                                        }} />
                                    </p>
                                </div>
                                {item?.priceOptions?.map((itm) => itm?.quantity && <div className='flex gap-4 items-center justify-between border-t border-gray-100 w-full pt-2 mt-2'>
                                    <p>{itm?.label}</p>
                                    <p>{itm?.quantity}</p>
                                    <p>A${itm?.price * itm?.quantity}</p>
                                </div>)}




                            </div>))}



                            <div className=''>
                                {CartData?.length > 0 ? <button className='bg-primary w-full text-white rounded-xl px-4 py-2'>Checkout Now</button> : <p className='text-center'>No Data</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-12 gap-4 xl:gap-8 2xl:gap-10'>
                <div className='col-span-12 md:col-span-7 xl:col-span-8 2xl:col-span-8'>
                    <h2 className='text-xl p-3'>{ProductData?.name}</h2>
                    <div className='product_inners'>
                        <div className='slde_banns'>
                            <div>
                                <img className='w-full h-96 object-cover rounded-2xl' src={DOMPurify.sanitize(CurrentViewImage || 'assets/front/product/slide1.png')} alt='banner' />
                            </div>
                            <div className='flex items-center justify-between gap-4 mt-6'>
                                {ProductData?.images && ProductData?.images?.map((item) => <img onClick={() => setCurrentViewImage(DOMPurify.sanitize(item?.itemUrl))} className={`w-full h-32 object-cover rounded-2xl ${CurrentViewImage == item?.itemUrl ? 'border border-primary' : ''}`} src={DOMPurify.sanitize(item?.itemUrl || 'assets/front/product/slide1.png')} alt='banner' />)}

                            </div>
                        </div>

                        <div className='flex items-center justify-between gap-4 mt-6'>
                            <div className='flex flex-col gap-4 border border-primary p-4 rounded-2xl w-44 items-center relative'>
                                <img className=' h-16 object-contain  ' src='assets/front/product/1.svg' alt='banner' />
                                <p className='text-sm font-regular'>Duration</p>
                            </div>
                            <div className='flex flex-col gap-4 border border-primary p-4 rounded-2xl w-44 items-center relative'>
                                <img className=' h-16 object-contain  ' src='assets/front/product/2.svg' alt='banner' />
                                <p className='text-sm font-regular'>Duration</p>

                                <ReactTooltip
                                    id="my-tooltip-7"
                                    classNameArrow="!border-b-[1.5px] !border-r-[1.5px] border-primary !rounded-[2px]"
                                    className="!z-[999] !text-sm !bg-[#F0E0FF] !shadow-box border !text-center !rounded-large border-primary !max-w-[190px] !p-2"
                                    place="bottom"
                                    variant="info"
                                    content="Lorem ipsum dolor sit amet consectetur. Sed sit netus ac nec ac molestie augue. Dolor platea vitae se."
                                />



                                <p data-tooltip-id="my-tooltip-7" className='text-gray-400 cursor-pointer absolute right-2 top-2'>
                                    <img className=' h-6 object-contain  ' src='assets/front/product/i.svg' alt='banner' />
                                </p>

                            </div>
                            <div className='flex flex-col gap-4 border border-primary p-4 rounded-2xl w-44 items-center relative'>
                                <img className=' h-16 object-contain  ' src='assets/front/product/3.svg' alt='banner' />
                                <p className='text-sm font-regular'>Duration</p>
                            </div>
                            <div className='flex flex-col gap-4 border border-primary p-4 rounded-2xl w-44 items-center relative'>
                                <img className=' h-16 object-contain  ' src='assets/front/product/4.svg' alt='banner' />
                                <p className='text-sm font-regular'>Duration</p>
                            </div>
                            <div className='flex flex-col gap-4 border border-primary p-4 rounded-2xl w-44 items-center relative'>
                                <img className=' h-16 object-contain  ' src='assets/front/product/5.svg' alt='banner' />
                                <p className='text-sm font-regular'>Duration</p>
                            </div>
                        </div>


                        <div className='flex gap-4 w-full mt-6'>
                            <div className='w-full'>
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                                    <div className='border border-[#D6D6D8] rounded-3xl p-6'>
                                        <h5 className='flex items-center gap-2 text-2xl font-bold'><IoCheckmark /> What`s included</h5>
                                        <ul className='mt-5 space-y-2 text-[16px]'>
                                            <li>Lorem ipsum dolor sit amet consectetur. </li>
                                            <li>Lorem ipsum dolor sit amet consectetur. </li>
                                            <li>Lorem ipsum dolor sit amet consectetur. </li>
                                            <li>Commodo pellentesque sed mauris neque nisi ut turpis ut etiam.  </li>
                                        </ul>
                                    </div>
                                    <div className='border border-[#D6D6D8] rounded-3xl p-6'>
                                        <h5 className='flex items-center gap-2 text-2xl font-bold'> <IoCloseOutline />
                                            What`s excluded</h5>
                                        <ul className='mt-5 space-y-2 text-[16px]'>
                                            <li>Lorem ipsum dolor sit amet consectetur. </li>
                                            <li>Lorem ipsum dolor sit amet consectetur. </li>
                                            <li>Lorem ipsum dolor sit amet consectetur. </li>
                                            <li>Commodo pellentesque sed mauris neque nisi ut turpis ut etiam.  </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='mt-6'>
                            <div className='border border-[#D6D6D8] rounded-3xl p-6'>
                                <h5 className='flex items-center gap-2 text-2xl font-bold'><img src="assets/front/product/navi.svg" className='h-6' alt='' /> Where you visit</h5>
                                <ul className='mt-5 space-y-2 text-[16px]'>
                                    <li>{ProductData?.locationAddress?.addressLine},{ProductData?.locationAddress?.city},{ProductData?.locationAddress?.state},{ProductData?.locationAddress?.country} </li>
                                    {ProductData?.region?.name ? <li>{ProductData?.address},{ProductData?.reqgion?.name},{ProductData?.city?.name},{ProductData?.country?.name} </li> : null}
                                </ul>
                            </div>
                        </div>


                        <div className='mt-6'>

                            <div className='border border-[#D6D6D8] rounded-3xl p-6'>
                                <div className="text-[#111827] text-[24px] font-bold leading-[34px]">
                                    Itinerary
                                </div>
                                <ul className="relative mt-6">
                                    <li className="relative pl-8  border-l-2 border-dashed pb-4 border-gray-200">
                                        <div className="absolute -left-[11px] top-1/2 -translate-y-1/2 w-5 h-5 bg-[#9837F1] rounded-full"></div>

                                        <div className="bg-[#F3E7FF] p-4 rounded-lg ">
                                            <p className="font-semibold text-lg text-[#151618]">{ProductData?.description && parse(`${ProductData?.description}`)}</p>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>



                        <div className='mt-6'>
                            <div className='border border-[#D6D6D8] rounded-3xl p-6'>
                                <h5 className='flex items-center gap-2 text-2xl font-bold'>Pickup locations</h5>

                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 xl:gap-8 mt-6'>
                                    <div className='flex flex-col gap-6'>
                                        <div className=" h-12 px-5 py-3.5 bg-white rounded-lg border border-gray-300 flex items-center gap-3">
                                            <div className="w-5 h-5 relative">
                                                <div className="w-[15.42px] h-[15.42px] absolute inset-0 m-auto">
                                                    <RiSearchLine />
                                                </div>
                                            </div>
                                            <input className="text-gray-400 text-base font-normal pl-2 w-full focus:outline-0 font-inter leading-tight" onChange={e => PickupLocationSearcher(e.target.value)} placeholder='Search' />

                                        </div>

                                        <div className='flex flex-col gap-4 xl:gap-6 h-[650px] overflow-auto'>
                                            {PickupLocations?.map((item) => (<div className={`flex flex-col justify-center gap-1 cursor-pointer ${SelectedPickupLocation?.address == item?.address ? 'border border-primary p-3' : ''}`} onClick={e => setSelectedPickupLocation(item)}>
                                                <div className="text-gray-400 text-sm font-normal font-inter leading-[18px]">Gallery</div>
                                                <div className="text-gray-900 text-base font-normal font-inter leading-relaxed">
                                                    {item?.locationName},{item.address}
                                                </div>
                                            </div>))}

                                            {!PickupLocations?.length ? <div className='text-center'>No Pickup Location yet</div> : null}

                                        </div>
                                    </div>
                                    <div className='map_hieghts'>

                                        <iframe
                                            key={`${SelectedPickupLocation?.longitude}-${SelectedPickupLocation?.latitude}`}
                                            src={generateSimpleMapUrl()}
                                            width="100%"
                                            height="750"
                                            allowFullScreen
                                            loading="lazy"
                                        ></iframe>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-span-12 md:col-span-5 xl:col-span-4 2xl:col-span-4'>
                    <div className='sideways_right'>
                        <div className="relative  border border-[#D6D6D8] rounded-[20px] p-4 flex flex-col justify-between">
                            {/* Price and Savings */}
                            <div className="flex flex-col items-center text-center mb-6">
                                <div className="text-[#151618] text-[24px] font-bold leading-[34px]">
                                    AUD ${parseFloat(ProductData?.advertisedPrice).toFixed(2)}
                                </div>
                            </div>

                            {/* Pricing Options */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                                {ProductData?.priceOptions?.map((item) => (<div className="border border-[#D6D6D8] rounded-[8px] flex justify-between items-center px-4 py-2">
                                    <div className="text-[#151618] text-[14px] font-semibold capitalize">{item?.label}</div>
                                    <div className="text-[#151618] text-[14px]">AUD ${parseFloat(item?.price).toFixed(2)}</div>
                                </div>))}

                            </div>

                            {/* Available Date */}
                            <div className="flex justify-center mb-6">
                                <div className="text-[#151618] text-[14px]">
                                    Next available date: <span className="font-semibold">January 21, 2023</span>
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="flex flex-col gap-4">
                                <button onClick={open} className="w-full bg-primary text-white rounded-[12px] py-3 text-center text-[16px] font-semibold leading-[20px]">
                                    Check Availability
                                </button>
                                <button onClick={e => { setProductState({ ...ProductState, cart: true }); setIsOpen(true) }} className="w-full border border-primary text-primary   rounded-[12px] py-3 text-center text-[16px] font-semibold leading-[20px]">
                                    Add to wishlist
                                </button>
                                <button className="w-full border border-primary text-primary   rounded-[12px] py-3 text-center text-[16px] font-semibold leading-[20px]">
                                    Gift this tour
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='col-span-12 mt-6'>

                    <div className='asked_quesns py-[80px]'>
                        <div className='grid grid-cols-12 gap-4 xl:gap-8'>
                            <div className='col-span-12 md:col-span-7 xl:col-span-8 '>
                                <div className='texts-iners'>
                                    <h5 className='text-gray-900 text-2xl font-bold  leading-[34px]'> Where you visit</h5>
                                    <p className='text-gray-900 text-base font-normal leading-relaxed'>This is where all the answers to your questions are.</p>

                                    <div className='mt-6'>
                                        <div className="w-full px-4">
                                            <div className=" ">
                                                <Disclosure as="div" className="border-b border-gray-200 pb-4 mb-6" defaultOpen={true}>
                                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                                        <span className="text-sm/6 font-medium text-black group-data-[hover]:text-black/80">
                                                            Important Note for Pickup Location
                                                        </span>
                                                        <GoPlus className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                    </DisclosureButton>
                                                    <DisclosurePanel className="mt-2 text-sm/5 text-black">
                                                        {ProductData?.pickupList?.additionalNotes}
                                                    </DisclosurePanel>
                                                </Disclosure>
                                                {/* <Disclosure as="div" className="border-b border-gray-200 pb-4 mb-6">
                                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                                        <span className="text-sm/6 font-medium text-black group-data-[hover]:text-black/80">
                                                            Lorem ipsum dolor?
                                                        </span>
                                                        <GoPlus className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                    </DisclosureButton>
                                                    <DisclosurePanel className="mt-2 text-sm/5 text-black">
                                                        Lorem ipsum dolor sit amet consectetur. Suspendisse in mattis nisi sem auctor convallis at nulla. Accumsan viverra bibendum sagittis amet. Lorem ipsum dolor sit amet consectetur. Suspendisse in mattis nisi sem auctor convallis at nulla. Accumsan viverra bibendum sagittis amet.
                                                    </DisclosurePanel>
                                                </Disclosure> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 md:col-span-5 xl:col-span-4'>
                                <div>
                                    <div className="w-full max-w-md h-auto p-6 bg-gray-200 rounded-2xl flex flex-col justify-start items-center space-y-4 relative">
                                        <div className="text-center text-gray-900 text-2xl font-bold leading-[34px]">
                                            Don’t see the answer you need?
                                        </div>
                                        <div className="text-center text-gray-900 text-lg font-normal leading-7">
                                            That’s ok. Just drop a message and we will get back to you ASAP.
                                        </div>
                                        <button className="px-5 py-3 bg-primary rounded-lg text-white text-base font-semibold leading-tight">
                                            Contact Us
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="flex flex-col gap-6">
                        <div className=" rounded-[20px] border border-[#D6D6D8] p-6">
                            <div className="text-[#111827] text-[24px] font-bold leading-[34px]">
                                Terms and Conditions
                            </div>
                            <div className="text-[#111827] text-[16px] font-normal leading-[26px] mt-4">
                                {ProductData?.terms && parse(`${ProductData?.terms}`)}
                            </div>
                        </div>

                        <div className=" rounded-[20px] border border-[#D6D6D8] p-6">
                            <div className="text-[#111827] text-[24px] font-bold leading-[34px]">
                                Cancellation & Refund Policy
                            </div>
                            <div className="text-[#111827] text-[16px] font-normal leading-[26px] mt-4">
                                {ProductData?.cancellationpolicy_name && parse(`${ProductData?.cancellationpolicy_name}`)}
                                <br />
                                {ProductData?.cancellationPolicyDays ? `${ProductData?.cancellationPolicyDays} days` : null}
                            </div>
                        </div>
                    </div>


                    <div className=''>

                    </div>



                </div>


                <div className='col-span-12 mt-6'>
                    <div className="">
                        <div className="border border-[#D6D6D8] rounded-2xl p-8">
                            <p className="text-lg font-bold mb-3 text-[16px] text-[#333]">
                                Reviews
                            </p>
                            <p className="flex items-center gap-1 font-semibold">
                                <i
                                    className="fa fa-star text-yellow-400 text-[25px]"
                                    aria-hidden="true"
                                ></i>{" "}
                                <span>4.9</span> . <span>reviews</span>{" "}
                            </p>
                            <div className="grid grid-cols-12 gap-4 mt-7 ">
                                <div className="col-span-12 md:col-span-4">
                                    <div className="py-2 px-3">
                                        <div className="flex gap-3 item-center">
                                            <img src="/assets/front/product/slide1.png" alt="" className="w-[50px] h-[50px] object-cover rounded-full" />
                                            <div>
                                                <p className="text-[16px] font-semibold">Finn</p>
                                                <span className="text-[15px] text-slate-400 font-noraml">
                                                    Febrary 2024
                                                </span>
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <p className="text-black-600 text-[15px] elipIt">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Vitae mollitia eum excepturi ipsum temporibus numquam
                                                architecto ab asperiores voluptatibus reiciendis illum
                                                itaque assumenda eligendi necessitatibus, iste repellat?
                                                Nulla, esse? Facere, a architecto cumque consequatur
                                                assumenda cum error repellendus porro mollitia!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 md:col-span-4">

                                    <div className="py-2 px-3">
                                        <div className="flex gap-3 item-center">
                                            <img src="/assets/front/product/slide1.png" alt="" className="w-[50px] h-[50px] object-cover rounded-full" />
                                            <div>
                                                <p className="text-[16px] font-semibold">Finn</p>
                                                <span className="text-[15px] text-slate-400 font-noraml">
                                                    Febrary 2024
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <p className="text-black-600 text-[15px] elipIt">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Vitae mollitia eum excepturi ipsum temporibus numquam
                                                architecto ab asperiores voluptatibus reiciendis illum
                                                itaque assumenda eligendi necessitatibus, iste repellat?
                                                Nulla, esse? Facere, a architecto cumque consequatur
                                                assumenda cum error repellendus porro mollitia!
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-12 md:col-span-4">
                                    <div className="py-2 px-3">
                                        <div className="flex gap-3 item-center">
                                            <img src="/assets/front/product/slide1.png" alt="" className="w-[50px] h-[50px] object-cover rounded-full" />
                                            <div>
                                                <p className="text-[16px] font-semibold">Finn</p>
                                                <span className="text-[15px] text-slate-400 font-noraml">
                                                    Febrary 2024
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <p className="text-black-600 text-[15px] elipIt">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Vitae mollitia eum excepturi ipsum temporibus numquam
                                                architecto ab asperiores voluptatibus reiciendis illum
                                                itaque assumenda eligendi necessitatibus, iste repellat?
                                                Nulla, esse? Facere, a architecto cumque consequatur
                                                assumenda cum error repellendus porro mollitia!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-span-12 py-16'>
                    <div className='recmview'>
                        <div className='text-center'>
                            <h6 className='text-[#111827] text-[34px] font-bold leading-[34px]'>You recently viewed</h6>
                        </div>

                        <div className='mt-10'>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 lg:gap-8'>
                                <div className='inner_cartds'>
                                    <div className="h-[450px] w-full rounded-xl bg-cover bg-center relative" style={{ backgroundImage: "url('assets/front/product/bg1.png')" }}>

                                        <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                                        <div className="absolute right-4 top-4">
                                            <GoHeart className='text-xl text-white' />
                                            {/* <GoHeartFill /> */}


                                        </div>


                                        <div className='absolute z-10 bottom-2 p-6 left-0 w-full'>

                                            <h5 className='line-clamp-2 text-white font-bold text-2xl'>Lorem ipsum dolor sit amet ull com</h5>

                                            <div className='text-white mt-4 flex flex-col gap-2'>
                                                <p className='flex items-center gap-2 font-normal '><IoLocationOutline /> Chiang Mai, Thailand</p>
                                                <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><FaStar />
                                                    4.9 (8 reviews)</p>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='flex items-center justify-between mt-4'>
                                        <p className='text-md'>from <span className='font-semibold text-lg text-primary  '>$98.00</span></p>
                                        <button className='px-4 py-1 text-xs text-primary   font-semibold rounded-md border border-primary'>View more prices</button>
                                    </div>
                                </div>

                                <div className='inner_cartds'>
                                    <div className="h-[450px] w-full rounded-xl bg-cover bg-center relative" style={{ backgroundImage: "url('assets/front/product/bg1.png')" }}>

                                        <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                                        <div className="absolute right-4 top-4">
                                            <GoHeart className='text-xl text-white' />
                                            {/* <GoHeartFill /> */}


                                        </div>


                                        <div className='absolute z-10 bottom-2 p-6 left-0 w-full'>

                                            <h5 className='line-clamp-2 text-white font-bold text-2xl'>Lorem ipsum dolor sit amet ull com</h5>

                                            <div className='text-white mt-4 flex flex-col gap-2'>
                                                <p className='flex items-center gap-2 font-normal '><IoLocationOutline /> Chiang Mai, Thailand</p>
                                                <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><FaStar />
                                                    4.9 (8 reviews)</p>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='flex items-center justify-between mt-4'>
                                        <p className='text-md'>from <span className='font-semibold text-lg text-primary  '>$98.00</span></p>
                                        <button className='px-4 py-1 text-xs text-primary   font-semibold rounded-md border border-primary'>View more prices</button>
                                    </div>
                                </div>

                                <div className='inner_cartds'>
                                    <div className="h-[450px] w-full rounded-xl bg-cover bg-center relative" style={{ backgroundImage: "url('assets/front/product/bg1.png')" }}>

                                        <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                                        <div className="absolute right-4 top-4">
                                            <GoHeart className='text-xl text-white' />
                                            {/* <GoHeartFill /> */}


                                        </div>


                                        <div className='absolute z-10 bottom-2 p-6 left-0 w-full'>

                                            <h5 className='line-clamp-2 text-white font-bold text-2xl'>Lorem ipsum dolor sit amet ull com</h5>

                                            <div className='text-white mt-4 flex flex-col gap-2'>
                                                <p className='flex items-center gap-2 font-normal '><IoLocationOutline /> Chiang Mai, Thailand</p>
                                                <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><FaStar />
                                                    4.9 (8 reviews)</p>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='flex items-center justify-between mt-4'>
                                        <p className='text-md'>from <span className='font-semibold text-lg text-primary  '>$98.00</span></p>
                                        <button className='px-4 py-1 text-xs text-primary   font-semibold rounded-md border border-primary'>View more prices</button>
                                    </div>
                                </div>

                                <div className='inner_cartds'>
                                    <div className="h-[450px] w-full rounded-xl bg-cover bg-center relative" style={{ backgroundImage: "url('assets/front/product/bg1.png')" }}>

                                        <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                                        <div className="absolute right-4 top-4">
                                            <GoHeart className='text-xl text-white' />
                                            {/* <GoHeartFill /> */}


                                        </div>


                                        <div className='absolute z-10 bottom-2 p-6 left-0 w-full'>

                                            <h5 className='line-clamp-2 text-white font-bold text-2xl'>Lorem ipsum dolor sit amet ull com</h5>

                                            <div className='text-white mt-4 flex flex-col gap-2'>
                                                <p className='flex items-center gap-2 font-normal '><IoLocationOutline /> Chiang Mai, Thailand</p>
                                                <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><FaStar />
                                                    4.9 (8 reviews)</p>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='flex items-center justify-between mt-4'>
                                        <p className='text-md'>from <span className='font-semibold text-lg text-primary  '>$98.00</span></p>
                                        <button className='px-4 py-1 text-xs text-primary   font-semibold rounded-md border border-primary'>View more prices</button>
                                    </div>
                                </div>







                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-span-12 py-16'>
                    <div className='recmview'>
                        <div className='text-center'>
                            <h6 className='text-[#111827] text-[34px] font-bold leading-[34px]'>We Recommended</h6>
                        </div>

                        <div className='mt-10'>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 lg:gap-8'>
                                <div className='inner_cartds'>
                                    <div className="h-[450px] w-full rounded-xl bg-cover bg-center relative" style={{ backgroundImage: "url('assets/front/product/bg1.png')" }}>

                                        <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                                        <div className="absolute right-4 top-4">
                                            <GoHeart className='text-xl text-white' />
                                            {/* <GoHeartFill /> */}


                                        </div>


                                        <div className='absolute z-10 bottom-2 p-6 left-0 w-full'>

                                            <h5 className='line-clamp-2 text-white font-bold text-2xl'>Lorem ipsum dolor sit amet ull com</h5>

                                            <div className='text-white mt-4 flex flex-col gap-2'>
                                                <p className='flex items-center gap-2 font-normal '><IoLocationOutline /> Chiang Mai, Thailand</p>
                                                <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><FaStar />
                                                    4.9 (8 reviews)</p>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='flex items-center justify-between mt-4'>
                                        <p className='text-md'>from <span className='font-semibold text-lg text-primary  '>$98.00</span></p>
                                        <button className='px-4 py-1 text-xs text-primary   font-semibold rounded-md border border-primary'>View more prices</button>
                                    </div>
                                </div>

                                <div className='inner_cartds'>
                                    <div className="h-[450px] w-full rounded-xl bg-cover bg-center relative" style={{ backgroundImage: "url('assets/front/product/bg1.png')" }}>

                                        <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                                        <div className="absolute right-4 top-4">
                                            <GoHeart className='text-xl text-white' />
                                            {/* <GoHeartFill /> */}


                                        </div>


                                        <div className='absolute z-10 bottom-2 p-6 left-0 w-full'>

                                            <h5 className='line-clamp-2 text-white font-bold text-2xl'>Lorem ipsum dolor sit amet ull com</h5>

                                            <div className='text-white mt-4 flex flex-col gap-2'>
                                                <p className='flex items-center gap-2 font-normal '><IoLocationOutline /> Chiang Mai, Thailand</p>
                                                <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><FaStar />
                                                    4.9 (8 reviews)</p>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='flex items-center justify-between mt-4'>
                                        <p className='text-md'>from <span className='font-semibold text-lg text-primary  '>$98.00</span></p>
                                        <button className='px-4 py-1 text-xs text-primary   font-semibold rounded-md border border-primary'>View more prices</button>
                                    </div>
                                </div>

                                <div className='inner_cartds'>
                                    <div className="h-[450px] w-full rounded-xl bg-cover bg-center relative" style={{ backgroundImage: "url('assets/front/product/bg1.png')" }}>

                                        <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                                        <div className="absolute right-4 top-4">
                                            <GoHeart className='text-xl text-white' />
                                            {/* <GoHeartFill /> */}


                                        </div>


                                        <div className='absolute z-10 bottom-2 p-6 left-0 w-full'>

                                            <h5 className='line-clamp-2 text-white font-bold text-2xl'>Lorem ipsum dolor sit amet ull com</h5>

                                            <div className='text-white mt-4 flex flex-col gap-2'>
                                                <p className='flex items-center gap-2 font-normal '><IoLocationOutline /> Chiang Mai, Thailand</p>
                                                <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><FaStar />
                                                    4.9 (8 reviews)</p>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='flex items-center justify-between mt-4'>
                                        <p className='text-md'>from <span className='font-semibold text-lg text-primary  '>$98.00</span></p>
                                        <button className='px-4 py-1 text-xs text-primary   font-semibold rounded-md border border-primary'>View more prices</button>
                                    </div>
                                </div>

                                <div className='inner_cartds'>
                                    <div className="h-[450px] w-full rounded-xl bg-cover bg-center relative" style={{ backgroundImage: "url('assets/front/product/bg1.png')" }}>

                                        <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                                        <div className="absolute right-4 top-4">
                                            <GoHeart className='text-xl text-white' />
                                            {/* <GoHeartFill /> */}


                                        </div>


                                        <div className='absolute z-10 bottom-2 p-6 left-0 w-full'>

                                            <h5 className='line-clamp-2 text-white font-bold text-2xl'>Lorem ipsum dolor sit amet ull com</h5>

                                            <div className='text-white mt-4 flex flex-col gap-2'>
                                                <p className='flex items-center gap-2 font-normal '><IoLocationOutline /> Chiang Mai, Thailand</p>
                                                <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><FaStar />
                                                    4.9 (8 reviews)</p>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='flex items-center justify-between mt-4'>
                                        <p className='text-md'>from <span className='font-semibold text-lg text-primary  '>$98.00</span></p>
                                        <button className='px-4 py-1 text-xs text-primary   font-semibold rounded-md border border-primary'>View more prices</button>
                                    </div>
                                </div>







                            </div>
                        </div>
                    </div>
                </div>


            </div>






            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
                <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div className="flex min-h-full bg-black/70 items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-[70%] rounded-xl bg-white  backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <DialogTitle as="h3" className="text-base/7 font-medium text-white">

                            </DialogTitle>


                            <div className='calender_shows w-full'>
                                <BookingCalendar setCartData={setCartData} ProductData={ProductData} setIsOpen={setIsOpen} ProductState={ProductState} />
                            </div>


                        </DialogPanel>
                    </div>
                </div>
            </Dialog>










        </div >
    )
}
