import React from 'react';

export default function FinalStep({ setSteps, setProvider }) {
    return (
        <div className=" ">
           
            <div className="px-6 py-8 max-w-2xl mx-auto bg-white shadow-lg rounded-lg mt-6">
                <div className="flex items-center space-x-2 mb-4 cursor-pointer" onClick={() => setProvider(null)}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-left text-gray-600 icon-xs"
                        style={{ strokeWidth: '3px', marginTop: '-2px' }}
                    >
                        <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                    <p href="#" className="text-sm text-primary  hover:text-indigo-800 transition-colors">
                        Select another provider
                    </p>
                </div>
                <hr className="my-4" />

                <div className="flex items-center mb-6">
                    <img
                        src="/assets/img/smtp/g1.svg"
                        alt="Provider"
                        className="w-12 h-12 rounded-full "
                    />
                    <div className="ml-4">
                        <h5 className="text-2xl font-semibold text-gray-800">Connect Your Google Account</h5>
                        <p className="text-sm text-gray-600 font-semibold">Gmail / G-Suite</p>
                    </div>
                </div>

                <h6 className="mt-6 pt-3 text-center text-gray-700 text-lg font-medium">
                    Enable 2-step verification &amp; generate App password
                </h6>
                <h6 className="text-center mt-4">
                    <a href="https://youtu.be/J4CtP1MBtOE?t=10" target="_blank" className="text-primary hover:text-blue-800 flex justify-center items-center space-x-2">
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="30"
                            width="30"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M9 7v8l7-4zm12-4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z"></path>
                        </svg>
                        <span className="ml-2">See tutorial video</span>
                    </a>
                </h6>

                <hr className="my-6 border-t-2 border-gray-200" />

                <ol className="mt-6 space-y-5">
                    <li className="text-gray-600">
                        Go to your Google Account's{' '}
                        <a href="https://myaccount.google.com/security" target="_blank" className="text-primary  hover:text-indigo-800">
                            Security Settings
                        </a>
                    </li>
                    <li className="text-gray-600">
                        Enable{' '}
                        <a href="https://myaccount.google.com/signinoptions/two-step-verification" target="_blank" className="text-primary  hover:text-indigo-800">
                            2-step verification
                        </a>
                    </li>
                    <li className="text-gray-600">
                        Create an{' '}
                        <a href="https://myaccount.google.com/apppasswords" target="_blank" className="text-primary  hover:text-indigo-800">
                            App password
                        </a>
                        <span className="text-sm text-gray-400"> (Select 'Other' for both App and Device)</span>
                    </li>
                </ol>

                <div className="flex justify-center mt-8 space-x-6">
                    <button className="px-6 py-3 bg-gray-200 text-gray-600 rounded-lg hover:bg-gray-300 flex items-center space-x-2 transition-all" onClick={() => setSteps(2)}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                        </svg>
                        <span>Back</span>
                    </button>
                    <button className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-blue-700 flex items-center space-x-2 transition-all" onClick={() => setSteps(4)}>
                        <span>Next</span>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}
