import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ links = [], currentPage = "" }) => {
  return (
    <div className="flex items-center gap-2 mb-4">
      {links.map((itm, idx) => {
        return (
          <Link
            key={idx}
            to={itm.link}
            className="text-sm text-[#75757A] font-normal hover:!text-primary  ">
            {itm.name}
          </Link>
        );
      })}
      <div className="text-typo font-medium">/</div>
      <div className="text-typo font-medium text-sm" aria-current="page">
        {currentPage}
      </div>
    </div>
  );
};

export default Breadcrumb;
