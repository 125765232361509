import React, { useEffect } from 'react'
import ApiClient from '../../methods/api/apiClient';
import { useSelector } from 'react-redux';
import loader from '../../methods/loader';

export default function ConnectionSetup({ setProvider, constants, Provider }) {

    const user = useSelector(state => state.user);
    const [formData, setFormData] = React.useState(null);
    const DataGetter = () => {
        loader(true);
        ApiClient.get(`api/smtp/listing?id=${user?.id || user?._id}`).then(res => {
            if (res.success) {
                if (res?.data?.length) {
                    setFormData(res?.data[0])
                } else {
                    setFormData(null)
                }
            }
            loader(false);
        })
    }

    const DisconnectHandler = () => {
        loader(true)
        ApiClient.delete(`api/smtp/delete?id=${formData?._id || formData?.id}`).then(res => {
            if (res?.success) {
                DataGetter()
            }
        })
    }
    useEffect(() => {
        DataGetter()
    }, [Provider])

    const ImageReturner = (name) => {
        switch (name) {
            case 'gmail':
                return '/assets/img/smtp/g1.svg'
                break;
            case 'outlook':
                return '/assets/img/smtp/g2.svg'
                break;
            default:
                return '/assets/img/smtp/g3.svg'
                break;
        }
    }
    return (
        <div>
            <div className=" flex justify-center items-center h-full mt-10">
                <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
                    <div className="mb-6">
                        <h6 className="text-2xl font-semibold text-center mb-2">{formData ? 'Connected' : 'Connect'} Existing Accounts</h6>
                        <hr className="border-t border-gray-300 mt-2" />
                    </div>
                    <div className="space-y-4">
                        {/* Gmail */}
                        <div className="flex items-center space-x-4">
                            <svg
                                className="w-6 h-6 text-green-600"
                                stroke="currentColor"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                                ></path>
                            </svg>
                            <span className="text-gray-600">{formData ? 'Connected' : 'Connect any'}   IMAP or SMTP email provider</span>
                        </div>

                        <hr className="border-t border-gray-300 mt-2" />

                        {!formData ? <>
                            {/* Gmail Provider */}
                            <div className="gap-5 w-full divide-x   cursor-pointer flex px-6 py-4 shadow-lg min-h-[60px] transition-all duration-300 ease-linear whitespace-nowrap rounded-lg" onClick={() => setProvider(constants.google)}>
                                <img
                                    className="w-10 h-10"
                                    src="/assets/img/smtp/g1.svg"
                                    alt="Gmail"
                                />
                                <div className='border-l border-gray-200 pl-4'>
                                    <p className="text-sm font-medium">Google</p>
                                    <h6 className="text-lg font-semibold">Gmail / G-Suite</h6>
                                </div>
                            </div>


                            {/* Gmail User Info */}





                            {/* Outlook Provider */}
                            <div className="gap-5 w-full divide-x  cursor-pointer flex px-6 py-4 shadow-lg min-h-[60px] transition-all duration-300 ease-linear whitespace-nowrap rounded-lg" onClick={() => setProvider(constants.outlook)}>
                                <img
                                    className="w-10 h-10"
                                    src="/assets/img/smtp/g2.svg"
                                    alt="Outlook"
                                />
                                <div className='border-l border-gray-200 pl-4'>
                                    <p className="text-sm font-medium">Microsoft</p>
                                    <h6 className="text-lg font-semibold">Office 365 / Outlook</h6>
                                </div>
                            </div>

                            {/* Other Providers */}
                            <div className="gap-5 w-full divide-x  cursor-pointer flex px-6 py-4 shadow-lg min-h-[60px] transition-all duration-300 ease-linear whitespace-nowrap rounded-lg" onClick={() => setProvider(constants.others)}>
                                <img
                                    className="w-10 h-10"
                                    src="/assets/img/smtp/g3.svg"
                                    alt="IMAP/SMTP"
                                />
                                <div className='border-l border-gray-200 pl-4'>
                                    <p className="text-sm font-medium">Any Provider</p>
                                    <h6 className="text-lg font-semibold">IMAP / SMTP</h6>
                                </div>
                            </div>
                        </> : <div className="flex items-center flex-wrap justify-between w-full gap-5 px-6 py-4 shadow-lg min-h-[60px] transition-all duration-300 ease-linear whitespace-nowrap rounded-lg bg-white">
                            {/* User Info */}
                            <div className="flex  items-center gap-4">
                                <img
                                    className="w-12 h-12 rounded-full"
                                    src={ImageReturner(formData?.service)}
                                    alt="User"
                                />
                                <div className="text-left">
                                    <p className="text-sm font-medium text-gray-600">{formData?.name}</p> {/* Replace with dynamic name */}
                                    <h6 className="text-lg font-semibold text-gray-800">{formData?.username}</h6> {/* Replace with dynamic email */}
                                </div>
                            </div>

                            {/* Disconnect Button */}
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-red-600 transition-all"
                                onClick={DisconnectHandler}
                            >
                                Disconnect
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
