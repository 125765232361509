
import ApiClient from '../methods/api/apiClient'
export const Subscription = (dataUser) => async (dispatch) => {
    if (!localStorage.getItem('token')) {
        return dispatch({
            type: 'Subscription', data: [
                "export-visitor-logs", "export-reviews", "export-wavier-template", "export-sales-data", "export-customer-data", "export-refund-data", "export-product-data", "export-delete-product-data", "export-reseller-data", "export-profitability-data", "export-emplates",
                "dashboard",
                "customers",
                "affiliates",
                "sales",
                "transactions",
                "billing",
                "plan",
                "users",
                "booking-page-settings",
                "data-connection",
                "email-templates",
                "email-list",
                "partners",
                "company-details",
                "inbox",
                "affiliate-request",
                "current-affiliate",
                "affiliate-database",
                "manage-google-tags",
                "instagram-analysis",
                "facebook-ads",
                "product-data",
                "product-insights",
                "price-management",
                "refundcancel-data",
                "historical-data",
                "customer-data",
                "customer-insights",
                "sales-data",
                "sales-insights",
                "waiver-database",
                "waiver-settings",
                "waiver-templates",
                "reviews-settings",
                "review-management",
                "review-insights",
                "widget-settings",
                "whatsapp",
                "chat",
                "chatbot",
                "google-ads",
                "google-analytics-summary",
                "forecasting",
                "marketing-insights",
                "profitability-data",
                "profit-analysis-date",
                "profit-analysis-month",
                "insights",
                "contract-templates",
                "invoice-templates",
                "list-of-invoices",
                "list-of-resellers",
                "resellers-insights",
                "costing",
                "budget",
                "settings",
                "update-logs",
                "cancellation-policies",
                "historical-data",
                "deleted-products"
            ]

        })
    } else {
        await ApiClient.get("api/my/plan", {}, '', '', { apiCall: true }).then((res) => {
            if (res.success) {
                if (dataUser?.on_trial && res?.data?.planId?.allModules) {
                    return dispatch({
                        type: 'Subscription', data: [
                            "export-visitor-logs", "export-reviews", "export-wavier-template", "export-sales-data", "export-customer-data", "export-refund-data", "export-product-data", "export-delete-product-data", "export-reseller-data", "export-profitability-data", "export-emplates",
                            "dashboard",
                            "customers",
                            "affiliates",
                            "sales",
                            "transactions",
                            "billing",
                            "plan",
                            "users",
                            "booking-page-settings",
                            "data-connection",
                            "email-templates",
                            "email-list",
                            "partners",
                            "company-details",
                            "inbox",
                            "affiliate-request",
                            "current-affiliate",
                            "affiliate-database",
                            "manage-google-tags",
                            "instagram-analysis",
                            "facebook-ads",
                            "product-data",
                            "product-insights",
                            "price-management",
                            "refundcancel-data",
                            "historical-data",
                            "customer-data",
                            "customer-insights",
                            "sales-data",
                            "sales-insights",
                            "waiver-database",
                            "waiver-settings",
                            "waiver-templates",
                            "reviews-settings",
                            "review-management",
                            "review-insights",
                            "widget-settings",
                            "whatsapp",
                            "chat",
                            "chatbot",
                            "google-ads",
                            "google-analytics-summary",
                            "forecasting",
                            "marketing-insights",
                            "profitability-data",
                            "profit-analysis-date",
                            "profit-analysis-month",
                            "insights",
                            "contract-templates",
                            "invoice-templates",
                            "list-of-invoices",
                            "list-of-resellers",
                            "resellers-insights",
                            "costing",
                            "budget",
                            "settings",
                            "update-logs",
                            "cancellation-policies",
                            "historical-data",
                            "deleted-products"
                        ]

                    })
                } else {
                    let ActivePlanData = { ...res.data, planFeatures: res?.data?.planId?.planFeatures?.filter((itemp) => itemp?.included) };
                    let data = ActivePlanData?.planAddons?.map((item) => item?.slug || item?.name) || [];
                    ActivePlanData?.planFeatures?.map((item) => {
                        let ourData = item?.slug || item?.name;
                        data?.push(ourData)
                    });
                    return dispatch({ type: 'Subscription', data: data || [] })
                }
            }
        })
    }
}; 