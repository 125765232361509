export const CostingAPIS = {
    list: 'api/template/product/costing/all',
    statusChange: 'api/template/product/costing/status',
    delete: 'api/template/product/costing',
    add: 'api/template/product/costing',
    edit: 'api/template/product/costing',
    view: 'api/template/product/costing',
    export: '',
}
export const CostingURL = {
    add: '/budget/add',
    edit: '/budget/edit',
    copy: '/budget/add',
    list: '/budget/template'
}
export const CostingConfig = {
    ListHeader: 'Budget Templates',
    AddHear: ' Budget  Template'
}

export const CostingNormalInputs = [
    { name: 'title', required: true, placeHolder: 'Enter Title here', outerClass: 'col-span-2 mb-2 lg:col-span-12', label: 'Title', type: 'text', className: 'shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary' },
]