
export default function TourGuideDashboard() {

    return (
        <>
            <div>
                <Layout>

                </Layout>
            </div>
        </>
    );
}
