import { useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Howitworks = () => {

    return <>
        <div className=" relative bg-white w-full overflow-auto h-screen">
       


            <div className="max-w-2xl p-6 mx-auto ">

            <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
  <div className="mb-12">
    <h1 className="text-3xl font-bold text-gray-900 mb-4">
      How to Enable IMAP on Google Accounts
    </h1>
    <p className="text-gray-600">Updated over 3 weeks ago</p>
  </div>

  <div className="space-y-6">
    <p className="text-lg text-gray-800">
      If you use Gmail as your email provider and need to enable IMAP for mailbox connectivity, follow these straightforward steps:
    </p>

    <ol className="list-decimal list-outside space-y-4 text-gray-800">
      <li>
        <h2 className="text-lg font-semibold">Open Gmail on Your Computer</h2>
        <p className="mt-2">
          Launch your web browser and navigate to Gmail by entering <span className="text-blue-500">"mail.google.com"</span> in the address bar.
        </p>
      </li>

      <li>
        <h2 className="text-lg font-semibold">Access Settings</h2>
        <div className="mt-2">
          <a
            href="https://downloads.intercomcdn.com/i/o/1038220901/e5153a9886f356de083b8bcc/image.png"
            target="_blank"
            rel="noreferrer"
            className="block"
          >
            <img
              src="https://downloads.intercomcdn.com/i/o/1038220901/e5153a9886f356de083b8bcc/image.png"
              alt="Gmail Settings"
              className="w-full rounded-lg shadow-md"
            />
          </a>
        </div>
        <p className="mt-2">
          Once you're logged into Gmail, locate and click on the gear icon (Settings) in the top right corner. This icon is next to your profile picture.
        </p>
      </li>

      <li>
        <h2 className="text-lg font-semibold">Navigate to Forwarding and POP/IMAP Settings</h2>
        <p className="mt-2">
          In the dropdown menu that appears after clicking the gear icon, select <span className="font-medium">"See all settings"</span> to access Gmail's full settings menu.
        </p>
      </li>

      <li>
        <h2 className="text-lg font-semibold">Enable IMAP</h2>
        <div className="mt-2">
          <a
            href="https://instantly.intercom-attachments-7.com/i/o/1015362280/a2410977b8f8b0576c0ef406/5ebvEB1JoGV0WAXJYpYzAMMUEb7_LjLCXlyHcNBjQQIPiXDQKi_rNXmeGxeC070_YOrzK3CQ0nwMrfRbMovd5obzDsV-bqs3MvUF9vSHBSrEqbzhhAwLhLGcYbHJis9RoY1l5Xqf-JKe3gKg5Yn-i7A"
            target="_blank"
            rel="noreferrer"
            className="block"
          >
            <img
              src="https://instantly.intercom-attachments-7.com/i/o/1015362280/a2410977b8f8b0576c0ef406/5ebvEB1JoGV0WAXJYpYzAMMUEb7_LjLCXlyHcNBjQQIPiXDQKi_rNXmeGxeC070_YOrzK3CQ0nwMrfRbMovd5obzDsV-bqs3MvUF9vSHBSrEqbzhhAwLhLGcYbHJis9RoY1l5Xqf-JKe3gKg5Yn-i7A"
              alt="Enable IMAP"
              className="w-full rounded-lg shadow-md"
            />
          </a>
        </div>
        <p className="mt-2">
          Within the Settings menu, click on the <span className="font-medium">"Forwarding and POP/IMAP"</span> tab located at the top.
        </p>
      </li>

      <li>
        <h2 className="text-lg font-semibold">Save Changes</h2>
        <p className="mt-2">
          Scroll down to the <span className="font-medium">"IMAP Access"</span> section. Ensure that the option for <span className="font-medium">"Enable IMAP"</span> is selected or checked.
        </p>
      </li>

      <li>
        <h2 className="text-lg font-semibold">Confirm and Save</h2>
        <p className="mt-2">
          After enabling IMAP, scroll to the bottom of the page and click the <span className="font-medium">"Save Changes"</span> button to apply your settings.
        </p>
      </li>
    </ol>
  </div>
</div>

            </div>
        </div>



    </>
}

export default Howitworks