import React, { useState } from 'react';
import ApiClient from '../../../methods/api/apiClient'
import loader from '../../../methods/loader';

export default function DetailsSaver({ setSteps, setProvider }) {
    const [email, setEmail] = useState('');
    const [Name, setName] = useState('')
    const [appPassword, setAppPassword] = useState('');

    const handleBackClick = () => {
        setSteps(3)
    };

    const handleConnectClick = (e) => {
        e.preventDefault()
        loader(true)
        const payload = {
            name: Name,
            service: 'Gmail',
            username: email,
            password: appPassword
        }
        ApiClient.post('api/create/smtp', payload).then((_res) => {
            if (_res?.success) {
                setProvider(null);
            }
            loader(false)
        })
    };

    return (
        <div className="">


            <div id="__next">
               
                <div className="px-6 py-8 max-w-xl mx-auto bg-white shadow-lg rounded-lg mt-6">
                    <div className="flex items-center mb-4">
                        <img
                            src="/assets/img/smtp/g1.svg" alt="Provider"
                            className="w-10 h-10 rounded-full "
                        />
                        <div className="ml-4">
                            <h5 className="text-2xl font-bold">Connect Your Google Account</h5>
                            <p className="text-sm text-gray-500">Gmail / G-Suite</p>
                        </div>
                    </div>

                    <form onSubmit={handleConnectClick} className="">

                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-semibold">Name <span className="text-red-500">*</span></label>
                            <input
                                id="text"
                                required
                                placeholder="Enter Name"
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-md mt-2"
                                value={Name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-semibold">Email <span className="text-red-500">*</span></label>
                            <input
                                id="email"
                                placeholder="Email address"
                                type="email"
                                required
                                className="w-full p-3 border border-gray-300 rounded-md mt-2"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="mb-6">
                            <label htmlFor="appPassword" className="block text-gray-700 font-semibold">App Password <span className="text-red-500">*</span></label>
                            <input
                                id="appPassword"
                                placeholder="Enter your app password"
                                type="password"
                                className="w-full p-3 border border-gray-300 rounded-md mt-2"
                                value={appPassword}
                                min={16}
                                required
                                onChange={(e) => setAppPassword(e.target.value)}
                            />
                            <p className="text-xs text-gray-500 mt-1">Enter your 16 character app password without spaces.</p>
                        </div>

                        <div className="flex justify-center gap-4 mt-6">

                            <button type='button' className="px-6 py-3 bg-gray-200 text-gray-600 rounded-lg hover:bg-gray-300 flex items-center space-x-2 transition-all" onClick={handleBackClick}>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    height="20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                </svg>
                                <span>Back</span>
                            </button>



                            <button
                                className="bg-primary text-white px-6 py-3 rounded-md hover:bg-green-700 focus:outline-none"
                                type='submit'
                            >
                                Connect
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
