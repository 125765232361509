import React from 'react';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { FiInfo } from "react-icons/fi";


export default function Step2({ setSteps }) {
    return (
        <div className="flex justify-center items-center mt-10">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl">


                <div className='max-w-lg mx-auto'>






                    {/* Go back link */}
                    <div className="flex items-center mb-4 cursor-pointer" onClick={() => setProvider(null)}>
                        <i className="text-gray-700">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-chevron-left text-dark icon-xs"
                                style={{ strokeWidth: '3px', marginTop: '-2px' }}
                            >
                                <g>
                                    <polyline points="15 18 9 12 15 6" />
                                </g>
                            </svg>
                        </i>
                        <p className="ml-2 text-sm text-gray-600">Select another provider</p>
                    </div>

                    <hr className="my-4" />


                    {/* Google Account connection section */}
                    <div className="flex items-center mb-3">
                        <img
                            src="/assets/img/smtp/g1.svg"
                            alt="Provider"
                            className="w-10"
                        />
                        <div className="ml-3">
                            <h5 className="text-xl font-semibold">Connect Your Google Account</h5>
                            <p className="text-sm text-gray-500">Gmail / G-Suite</p>
                        </div>
                    </div>




                    <div className="mt-6">
                        <h3 className="text-xl font-semibold text-center text-primary   mb-6">Select a connection option</h3>

                        <div className="flex flex-wrap gap-6 justify-center">
                            {/* Option 1 Button */}
                            {/* <button
                            className="w-full md:w-1/2 bg-primary text-white p-4 rounded-lg shadow-lg hover:bg-primary transition duration-300"
                            style={{ height: '270px' }}
                        >
                            <h4 className="text-xl font-semibold">Option 1: oAuth</h4>
                            <div className="mt-4 space-y-2 text-left">
                                <p className="flex items-center space-x-2">
                                    <svg width="20" height="20" fill="currentColor" className="text-white">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                    </svg>
                                    <span>Easier to setup</span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <svg width="20" height="20" fill="currentColor" className="text-white">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                    </svg>
                                    <span>More stable and less disconnects</span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <svg width="20" height="20" fill="currentColor" className="text-white">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                    </svg>
                                    <span>Available for GSuite accounts</span>
                                </p>
                            </div>
                            <div className="mt-4">
                                <p className="text-sm bg-green-100 text-green-600 px-3 py-1 rounded-full inline-block">Recommended</p>
                            </div>
                        </button> */}

                            {/* Option 2 Button */}
                            <button
                                className="w-96 bg-white text-primary   p-4 rounded-lg shadow-lg border-2 border-gray-300 hover:border-primary hover:bg-gray-100 transition duration-300"

                                onClick={() => setSteps(3)}
                            >
                                <div className="">
                                    <h6 className="text-center font-[700] text-[15px] text-primary mb-4"> App Password</h6>
                                    <div className="flex items-center mt-2">
                                        <IoCheckmarkCircleSharp className="text-[25px] text-[#3c4858]" />
                                        <p className="ml-2 text-[15px] font-[600] text-[#3c4858]">Easier to setup</p>
                                    </div>
                                    <div className="flex items-center mt-2">
                                        <FiInfo className="text-[25px] text-[#f17425]" />
                                        <p className="ml-2 text-[15px] font-[600] text-[#3c4858]">More stable and less disconnects</p>
                                    </div>
                                    <div className="flex items-center mt-2">
                                        <FiInfo className="text-[25px] text-[#f17425] " />
                                        <p className="ml-2 text-[15px] font-[600] text-[#3c4858]">Available for GSuite accounts</p>
                                    </div>

                                </div>
                            </button>
                        </div>
                    </div>


                    <div className="flex justify-center mt-8 space-x-6">
                    <button className="px-6 py-3 bg-gray-200 text-gray-600 rounded-lg hover:bg-gray-300 flex items-center space-x-2 transition-all" onClick={() => setSteps(1)}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                        </svg>
                        <span>Back</span>
                    </button>
                    <button className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-blue-700 flex items-center space-x-2 transition-all"  onClick={() => setSteps(3)}>
                        <span>Next</span>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                        </svg>
                    </button>
                </div>





                </div>
            </div>
        </div>
    );
}
